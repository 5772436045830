import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    QRCodeModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200", "localhost:3001","localhost:44366", "dms.orpgujarat.com","hrmsapi.orpgujarat.com","api.rojgarsetu.org"],
        // allowedDomains: ["http://sepdemo.orpgujarat.com", "http://sepapi.orpgujarat.com"],
        //disallowedRoutes: [""],
      }
    }),
    SharedModule
  ],
  providers: [
    {
   //   provide: 'API_BASE_URL', useValue: 'https://localhost:44366/'
     provide: 'API_BASE_URL', useValue: 'https://hrmsapi.orpgujarat.com/'
    }
    //https://detdemo.orpgujarat.com
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
