import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
 // { path: '', redirectTo: 'home', pathMatch: 'full' },
  
 
  {
    path: '',
    data: { breadcrumb: 'Home' },
    //loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    //loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'account',
    data: { breadcrumb: 'Account' },
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'contact-us',
    data: { breadcrumb: 'Contact Us' },
    loadChildren: () => import('./contact/contact.module').then(mod => mod.ContactModule)
  },
  {
    path: 'admin',
    data: { breadcrumb: 'Admin' },
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: 'user',
    data: { breadcrumb: 'User' },
    loadChildren: () => import('./job-seeker/job-seeker.module').then(m => m.JobSeekerModule)
  },
 
  {
    path: 'employer',
    data: { breadcrumb: 'Employer' },
    loadChildren: () => import('./employer/employer.module').then(m => m.EmployerModule)
  },
  {
    path: 'district',
    data: { breadcrumb: 'District' },
    loadChildren: () => import('./district/district.module').then(m => m.DistrictModule)
  },
  {
    path: 'project',
    data: { breadcrumb: 'IndexTb' },
    loadChildren: () => import('./regional/regional.module').then(m => m.RegionalModule)
  },
  {
    path: 'indextb',
    data: { breadcrumb: 'Admin' },
    loadChildren: () => import('./state/state.module').then(m => m.StateModule)
  },
  // {
  //   path: 'jobfair',
  //   data: { breadcrumb: 'Job Fair' },
  //   loadChildren: () => import('./job-fair/job-fair.module').then(m => m.JobFairModule)
  // },
  {
    path: 'profile',
    data: { breadcrumb: 'Profile' },
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'page',
    data: { breadcrumb: 'Page' },
    loadChildren: () => import('./page/page.module').then(m => m.PageModule)
   // loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: "No Access" }
  },  
  {
    path: '404',
    component: NotFoundComponent,
    data: { breadcrumb: "404 Error" }
  },
  // { path: 'jobfair', loadChildren: () => import('./job-fair/job-fair.module').then(m => m.JobFairModule) },
  {
    path: '**',
    redirectTo: '/404',
    data: { breadcrumb: "404 Error" }
    //data: { breadcrumb: 'Home' },
    //loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
