import { LoaderService } from './../../services/loader.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() show: boolean = false;
  @Input() height: string;
  private sub: Subscription;

  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.sub = this.loaderService.loader$
    .subscribe((val: boolean) => {
      this.show = val;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


}
