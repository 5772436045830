import { Router, ActivatedRoute } from '@angular/router';
import {
	Component,
	Input,
	OnInit,
	OnChanges
} from '@angular/core';

@Component({
	selector: 'pagination',
	templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit, OnChanges {
	@Input('total-rows') totalRows: number;
	@Input('page-size') pageSize: number;
	@Input('current-page') currentPage: number;

	pages: any[];
	firstPage: number = 1;
	lastPage: number;
	nextPage: number;
	prevPage: number;

	currentRoute: string;
	sort: string;
	sortDir: string;

	linkCount: number = 10;
	dispstring: string = "";


	constructor(private router: Router, private route: ActivatedRoute) {
	}

	ngOnChanges() {
		var pagesCount = Math.ceil(this.totalRows / this.pageSize);
		this.lastPage = Number(pagesCount);
		var last = Number(this.currentPage) + (this.linkCount / 2);
		var first = last - this.linkCount + 1;
		if (last > this.lastPage) {
			first -= last - this.lastPage;
			last = this.lastPage;
		}
		if (first <= 0) {
			last = Math.min(last + (0 - first), this.lastPage);
			first = 1;
		}

		this.pages = [];
		for (var i = first; i <= last; i++)
			this.pages.push(i);

		this.nextPage = Number(this.currentPage) + 1;
		this.prevPage = Number(this.currentPage) - 1;

		//{{((prevPage)*pageSize)+1}} To {{((prevPage+1)*pageSize)}}  From {{totalRows}}
		var currentindexrecoStart = ((this.prevPage) * this.pageSize) + 1;
		var currentindexrecoEnd = ((this.prevPage + 1) * this.pageSize);
		var totalrecord = this.totalRows;

		if (totalrecord > currentindexrecoEnd) {
			this.dispstring = currentindexrecoStart + " to " + currentindexrecoEnd + " from " + totalrecord;
		}
		else {
			if (totalrecord != 0) {
				this.dispstring = currentindexrecoStart + " to " + totalrecord + " from " + totalrecord;
			}
		}
	}

	ngOnInit() {
		this.route.queryParams
			.subscribe(params => {
				this.currentRoute = this.router.url.split('?')[0];
				this.currentPage = params.page | 1;
				this.sort = params.sort;
				this.sortDir = params.sortDir;
			});
	}

	getPageParams(page: number) {
		let query = this.QueryStringToJSON(this.router.url);
		query.page = page;
		return query;
	}

	QueryStringToJSON(query) {
		query = query.split('?').length >= 2 ? query.split('?')[1] : "";
		if (query) {
			let pairs = query.split('&');

			var result = {};
			pairs.forEach((pair) => {
				let pair_t = pair.split('=');
				result[pair_t[0]] = decodeURIComponent(pair_t[1] || '');
			});

			return JSON.parse(JSON.stringify(result));
		}
		else {
			return {};
		}
	}

	get countState() {
		let currentRecord=this.totalRows < ((this.pageSize * (this.currentPage - 1)) + this.pageSize) ? this.totalRows :((this.pageSize * (this.currentPage - 1)) + this.pageSize);
		return (this.pageSize * (this.currentPage - 1) + 1) 
		+ ' - ' + 
		currentRecord
		+ ' of ' + this.totalRows
	}
	// get countState() {
	// 	let currentRecord=this.queryResult.totalRows <  ((this.queryResult.pageSize * (this.queryResult.pageNumber - 1)) + this.queryResult.pageSize) ? this.queryResult.totalRows : ((this.queryResult.pageSize * (this.queryResult.pageNumber - 1))+ this.queryResult.pageSize);
	// 			return (this.queryResult.pageSize * (this.queryResult.pageNumber - 1) + 1) 
	// 		+ ' - ' +     
	// 		currentRecord
	// 		+ ' of ' + 
	// 		this.queryResult.totalRows
	// 		}
}