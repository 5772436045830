import { IProfile } from './../../../_models/account/IUser';
import { ProfileService } from './../../../_api/services/profile.service';
import { AuthService } from './../../../account/services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.css']
})
export class UserBarComponent implements OnInit {
  profile$: Observable<IProfile>;
  @Input() class: string = "";
  email: string = '';

  constructor(
    private authService: AuthService,
    private profileService: ProfileService
    ) {
    this.email = this.authService.currentUser.sub;
  }

  ngOnInit(): void {
    this.profile$ = this.profileService.profile$;
    this.profileService.getProfile();
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn();
  }
  
  isInRole(role: string) { 
    
    return this.authService.isInRole(role);
  }

  logout() {
    this.authService.logout();
  }
}
