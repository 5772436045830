import { AuthService } from './../../../account/services/auth.service';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {
  @Input() class: string = "navbar-dark bg-dark bg-transparent";
  @Input() header: string = "header-absolute";
  @Input() top: string = "";
  @Input() hideMenu: boolean = false;
  @Input() hideUser: boolean = false;
  @Input() hideCall: boolean = false;
  @ViewChild('stickyMenu') menuElement: ElementRef;
  sticky: boolean = false;
  elementPosition: any;
  isCollapsed: boolean = true;
  isSearch: boolean = false;
  constructor(
    private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll > this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }
}
